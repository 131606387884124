















































import { Component, Vue } from 'vue-property-decorator'
import Tinymce from '@/components/Tinymce/index.vue'
import api from '@/api'

import {quillEditor} from 'vue-quill-editor' //调用编辑器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {imageUrl} from '@/utils/common'

// import ImageResize from 'quill-image-resize-module';
// import * as Quill from "quill";
//
// Quill.register('modules/imageResize', ImageResize);


@Component({
  name: 'TinymceDemo',
  components: {
    Tinymce,
    quillEditor
  }
})
export default class extends Vue {
  baseUrl = imageUrl;
  info = {
    informationType: '1',
    influenceRange: 'zhike',
    informationName: '',
    weight: 0,
    content: ''
  };
  text = '上传配图';

  dataAuth: any = {};
  model: any = {
    OSSAccessKeyId: "",
    signature: "",
    policy: "",
    key: "",
    callback: "",
    file: "",
    success_action_status: ''
  };

  handleAvatarSuccess(res: any, file: any) {
    console.log('上传成功', res.data);
    this.handleSuccess(res.data)
  }

  beforeAvatarUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.$message.error("上传大小不能超过 2MB!");
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let _that = this;
    return new Promise(function (resolve, reject) {
      api
              .post("J27501", {
                fileName: file.name,
                softLink: ''
              })
              .then((data) => {
                console.log('上传中');
                _that.dataAuth['OSSAccessKeyId'] = data.OSSAccessKeyId;
                _that.dataAuth['signature'] = data.signature;
                _that.dataAuth['policy'] = data.policy;
                _that.dataAuth['key'] = data.key;
                _that.dataAuth['callback'] = data.callback;
                _that.dataAuth['success_action_status'] = data.success_action_status;
                resolve(true);
              })
              .catch(() => {
                reject(false);
              });
    });
  }

  file: any = {};

  editorOption = {
    placeholder: '在这里输入内容',
    modules: {
      // imageResize: {
      //   displayStyles: {
      //     backgroundColor: 'black',
      //     border: 'none',
      //     color: 'white'
      //   },
      //   modules: ['Resize', 'DisplaySize', 'Toolbar']
      // },
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], //  加粗、倾斜、下划线、删除线
        ['blockquote', 'code-block'],// 引用代码块
        [{'header': 1}, {'header': 2}], // 标题，键值对应的形式，1，2 表示字体大小
        [{'list': 'ordered'}, {'list': 'bullet'}], // 列表
        [{'script': 'sub'}, {'script': 'super'}], // 上下标
        [{'indent': -1}, {'indent': +1}], // 缩进
        [{'direction': 'rtl'}], // 文本方向
        [{'size': ['small', false, 'large', 'huge']}], // 字体大小
        [{'header': [1, 2, 3, 4, 5, 6, false]}], // 几级标题
        [{'color': []}, {'background': []}], // 字体颜色，字体背景颜色
        [{'font': []}], // 字体
        [{'align': []}], // 对齐方式
        ['clean'], //清除
      ]
    }
  };


  escapeStringHTML(str: any) {
    str = str.replace(/</g, '<');
    str = str.replace(/>/g, '>');
    return str
  }

  focus() {
    (this.$refs.myQuillEditor as any).quill.focus();
  }

  handleSuccess(res: any) {

    // 获取富文本组件实例
    let quill = (this.$refs.myQuillEditor as any).quill;

    // 如果上传成功
    if (res) {
      let NODE_ENV = this.baseUrl
      // 获取光标所在位置
      let length = quill.getSelection().index;
      // 插入图片，res为服务器返回的图片链接地址
      quill.insertEmbed(length, 'image', NODE_ENV + res)
      // 调整光标到最后
      quill.setSelection(length + 1)
    } else {
      // 提示信息，需引入Message
      this.$message.error('图片插入失败')
    }
  }

  weight = '';

  sure() {
    this.info.weight = Number(this.weight)
    if (this.$route.query.influenceRange) {
      this.info.influenceRange = this.$route.query.influenceRange as string
    }
    api
            .post(this.informationCode ? 'J27183' : 'J27182', this.info)
            .then(res => {
              this.$message.success('操作成功')
              this.info = {
                informationType: '1',
                influenceRange: 'zhike',
                informationName: '',
                weight: 0,
                content: ''
              }
              this.weight = ''
              this.$router.push({
                name: 'applet_information'
              })
            })
  }

  read(informationCode: any) {
    api
            .post('J27184', {informationCode: informationCode})
            .then(res => {
              this.weight = res.weight
              this.info = res
              this.info.content = this.escapeStringHTML(res.content)
            })
  }

  get editor() {
    return (this.$refs.myQuillEditor as any).quill
  }

  informationCode = '';

  activated() {
    this.info = {
      informationType: '1',
      influenceRange: 'zhike',
      informationName: '',
      weight: 0,
      content: ''
    }
    this.weight = ''
    let {informationCode} = this.$route.query
    this.informationCode = informationCode as string

    if (informationCode) {
      this.read(informationCode)
    }
  }
};
